<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="shape"
        transform="translate(-212.000000, -625.000000)"
        fill-rule="nonzero"
      >
        <g
          id="Group-6"
          transform="translate(123.000000, 625.000000)"
        >
          <g id="Group-7">
            <g
              id="Group-3"
              transform="translate(5.000000, 0.000000)"
            >
              <g
                id="iconmonstr-github-3"
                transform="translate(84.000000, 0.000000)"
              >
                <path
                  id="Shape"
                  d="M25.3333333,0 L6.66666667,0 C2.98533333,0 0,2.98533333 0,6.66666667 L0,25.3333333 C0,29.0146667 2.98533333,32 6.66666667,32 L25.3333333,32 C29.016,32 32,29.0146667 32,25.3333333 L32,6.66666667 C32,2.98533333 29.016,0 25.3333333,0 Z M19.3786667,26.12 C18.8386667,26.224 18.6666667,25.892 18.6666667,25.608 L18.6666667,22.6813333 C18.6666667,21.6853333 18.3173333,21.0373333 17.9333333,20.7066667 C20.3093333,20.4426667 22.8053333,19.54 22.8053333,15.444 C22.8053333,14.2786667 22.3893333,13.3266667 21.708,12.5813333 C21.8173333,12.312 22.1826667,11.2266667 21.6026667,9.75866667 C21.6026667,9.75866667 20.708,9.472 18.672,10.852 C17.8186667,10.612 16.9066667,10.496 16,10.4906667 C15.0933333,10.4946667 14.1813333,10.612 13.3293333,10.8493333 C11.292,9.46933333 10.396,9.756 10.396,9.756 C9.81733333,11.2253333 10.1826667,12.3093333 10.2933333,12.58 C9.61066667,13.3266667 9.19466667,14.2773333 9.19466667,15.4426667 C9.19466667,19.528 11.684,20.444 14.0546667,20.7146667 C13.7493333,20.9813333 13.4733333,21.4506667 13.3773333,22.1413333 C12.768,22.4133333 11.2253333,22.884 10.2733333,21.2533333 C10.2733333,21.2533333 9.70933333,20.2293333 8.63733333,20.1533333 C8.63733333,20.1533333 7.59733333,20.14 8.564,20.8026667 C8.564,20.8026667 9.264,21.1306667 9.74933333,22.3626667 C9.74933333,22.3626667 10.3666667,24.2666667 13.3333333,23.6213333 L13.3333333,25.6066667 C13.3333333,25.888 13.1613333,26.2186667 12.6293333,26.12 C8.38933333,24.7106667 5.33333333,20.712 5.33333333,16 C5.33333333,10.108 10.1093333,5.33333333 16,5.33333333 C21.8906667,5.33333333 26.6666667,10.108 26.6666667,16 C26.6666667,20.7106667 23.6146667,24.708 19.3786667,26.12 L19.3786667,26.12 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Github',
};
</script>
