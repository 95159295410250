<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from './components/Home.vue';
// import Works from './components/Works.vue';
// import Knowledge from './components/Knowledge.vue';
// import About from './components/About.vue';
let ROOT_PATH = 'https://salasjorge.com'

export default {
  name: 'App',
  components: {
    Home,
    // Works,
    // Knowledge,
    // About,
  },
  data() {
    return {
       logo: ROOT_PATH + require('./assets/og-image.jpg'),
    };
  },
    metaInfo() {
    return {
      meta: [
        // Twitter Card
        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:title', content: 'Jorge Salas'},
        {name: 'twitter:description', content: 'Jorge Salas personal website, Frontend Developer & UX/UI Designer 👽'},
        {name: 'twitter:image', content: this.logo},

        // Facebook OpenGraph
        {property: 'og:title', content: 'Jorge Salas'},
         {property: 'og:url', content: 'https://salasjorge.com'},
        {property: 'og:site_name', content: 'salasjorge.com'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  this.logo},
        {name: 'author', content: 'Jorge Salas'},
        {property: 'og:description', content: 'Jorge Salas personal website, Frontend Developer & UX/UI Designer 👽'}
      ]
    };
  },
};
</script>
<style lang="scss" scoped>
</style>