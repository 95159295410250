<template>
  <div class="TheLoader">
    <div class="TheLoader__wrapper-loader">
      <img
        src="../assets/home/loader-img.jpg"
        alt="loader"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheLoader',
};
</script>

<style lang="scss" scoped>
  .TheLoader {
    @include layout;
    background: $white;
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper-loader {
      width: 230px;

      @include tablet-m() {
        width: 280px;
      }
      img {
        width: 100%;
      }
    }
  }
</style>

