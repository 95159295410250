<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="shape"
        transform="translate(-254.000000, -625.000000)"
        fill-rule="nonzero"
      >
        <g
          id="Group-6"
          transform="translate(123.000000, 625.000000)"
        >
          <g id="Group-7">
            <g
              id="Group-3"
              transform="translate(5.000000, 0.000000)"
            >
              <g
                id="iconmonstr-linkedin-3"
                transform="translate(126.000000, 0.000000)"
              >
                <path
                  id="Shape"
                  d="M25.3333333,0 L6.66666667,0 C2.98533333,0 0,2.98533333 0,6.66666667 L0,25.3333333 C0,29.0146667 2.98533333,32 6.66666667,32 L25.3333333,32 C29.016,32 32,29.0146667 32,25.3333333 L32,6.66666667 C32,2.98533333 29.016,0 25.3333333,0 Z M10.6666667,25.3333333 L6.66666667,25.3333333 L6.66666667,10.6666667 L10.6666667,10.6666667 L10.6666667,25.3333333 Z M8.66666667,8.976 C7.37866667,8.976 6.33333333,7.92266667 6.33333333,6.624 C6.33333333,5.32533333 7.37866667,4.272 8.66666667,4.272 C9.95466667,4.272 11,5.32533333 11,6.624 C11,7.92266667 9.956,8.976 8.66666667,8.976 Z M26.6666667,25.3333333 L22.6666667,25.3333333 L22.6666667,17.8613333 C22.6666667,13.3706667 17.3333333,13.7106667 17.3333333,17.8613333 L17.3333333,25.3333333 L13.3333333,25.3333333 L13.3333333,10.6666667 L17.3333333,10.6666667 L17.3333333,13.02 C19.1946667,9.572 26.6666667,9.31733333 26.6666667,16.3213333 L26.6666667,25.3333333 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
    export default {
        name: 'Linkedin'
    }
</script>
