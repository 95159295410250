<template>
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        class="shape"
        transform="translate(-128.000000, -625.000000)"
        fill-rule="nonzero"
      >
        <g
          id="Group-6"
          transform="translate(123.000000, 625.000000)"
        >
          <g id="Group-7">
            <g
              id="Group-3"
              transform="translate(5.000000, 0.000000)"
            >
              <g id="iconmonstr-dribbble-3">
                <path
                  id="Shape"
                  d="M17.3466667,17.584 C18.1693333,19.7346667 18.776,21.948 19.1613333,24.2146667 C16.1853333,25.3626667 12.9813333,24.8066667 10.5906667,22.9413333 C12.3413333,20.1973333 14.576,18.4106667 17.3466667,17.584 L17.3466667,17.584 Z M16.64,15.8693333 C16.4493333,15.4426667 16.252,15.0186667 16.044,14.596 C13.5733333,15.3746667 10.62,15.768 7.2,15.7733333 L7.18666667,16 C7.18666667,18.1386667 7.95466667,20.1026667 9.228,21.6306667 C11.1586667,18.7333333 13.636,16.8093333 16.64,15.8693333 L16.64,15.8693333 Z M12.024,8.14666667 C9.76,9.29733333 8.06533333,11.392 7.448,13.9133333 C10.4293333,13.892 13.008,13.5653333 15.1693333,12.9306667 C14.2546667,11.292 13.208,9.696 12.024,8.14666667 Z M21.372,9.03066667 C19.8826667,7.88133333 18.024,7.188 16,7.188 C15.252,7.188 14.5306667,7.292 13.8386667,7.468 C15.0026667,9.03333333 16.036,10.6466667 16.94,12.3013333 C18.8226667,11.504 20.3,10.412 21.372,9.03066667 L21.372,9.03066667 Z M32,6.66666667 L32,25.3333333 C32,29.0146667 29.016,32 25.3333333,32 L6.66666667,32 C2.984,32 0,29.0146667 0,25.3333333 L0,6.66666667 C0,2.98533333 2.984,0 6.66666667,0 L25.3333333,0 C29.016,0 32,2.98533333 32,6.66666667 Z M26.6666667,16 C26.6666667,10.1093333 21.8906667,5.33333333 16,5.33333333 C10.1093333,5.33333333 5.33333333,10.1093333 5.33333333,16 C5.33333333,21.8906667 10.1093333,26.6666667 16,26.6666667 C21.8906667,26.6666667 26.6666667,21.8906667 26.6666667,16 Z M17.792,13.944 C18.032,14.4386667 18.256,14.9373333 18.4746667,15.4373333 C20.3933333,15.1066667 22.4986667,15.1266667 24.7866667,15.5026667 C24.6746667,13.532 23.9226667,11.7333333 22.724,10.316 C21.4946667,11.848 19.844,13.056 17.792,13.944 L17.792,13.944 Z M19.1853333,17.1933333 C19.9306667,19.188 20.5026667,21.2333333 20.8906667,23.324 C22.8906667,21.984 24.3053333,19.844 24.696,17.368 C22.688,17.02 20.852,16.9586667 19.1853333,17.1933333 L19.1853333,17.1933333 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
    export default {
        name: 'Dribbble'
    }
</script>

<style lang="scss" scoped>

</style>