import Vue from 'vue';
import App from './App.vue';
import VueKinesis from 'vue-kinesis';
import Meta from 'vue-meta';


import '@/sass/main.scss';

Vue.use(VueKinesis);
Vue.use(Meta);
Vue.config.productionTip = false;

new Vue({
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  render: (h) => h(App),
}).$mount('#app');
