<template>
  <kinesis-container>
    <div class="Home">
      <transition name="fade">
        <TheLoader v-if="isLoading" />
      </transition>

      <div class="Home__container">
        <kinesis-element
          class="parallax"
          :strength="10"
          type="translate"
        >
          <p class="Home__background">
            hello
          </p>
        </kinesis-element>
        <!-- <p class="Home__menu">Menú</p> -->
        <div class="Home__wrapper-top">
          <div class="Home__wrapper-top--btn-cv o-buttons">
            <a
              :href="`${publicPath}CV_JorgeSalas.pdf`"
              download
            > download cv </a>
          </div>
        </div>
        <!-- Name , Surname, Year-->
        <div class="Home__wrapper-title">
          <p class="Home__title">
            <span class="Home__title-name o-titles-main">Jorge</span>
            <span class="Home__title-lastnameyear">
              <span class="Home__title-lastnameyear-lastname o-titles-subtitle">
                Salas
                <span class="Home__title-lastnameyear-year o-titles-year">1992</span>
              </span>
            </span>
          </p>
        </div>
        <kinesis-element
          :strength="3"
        >
          <!-- Image -->
          <div class="Home__wrapper-img">
            <div class="Home__square-img">
              <img
                src="../assets/home/perfil2.png"
                alt=""
              >
            </div>
            <!-- firma, puesto de trabajo -->
            <div class="Home__wrapper-sign">
              <Sign class="sign" />
              <span class="Home__wrapper-sign--ux">
                <span>UX/UI</span> <span>Designer</span>
              </span>
              <span class="Home__wrapper-sign--front">
                <span>&amp; Frontend</span> <span>Developer</span>
              </span>
            </div>
            <!-- container redes sociales -->
            <div class="Home__rrss">
              <div class="Home__wrapper-top--btn-contact o-buttons">
                <a href="mailto:jorge.rooms1992@gmail.com"> contact me </a>
              </div>
              <div class="Home__rrss--urls">
                <a
                  href="https://bitbucket.org/jsalas99/workspace/projects/JOR"
                  target="_blank"
                >
                  <Github />
                </a>
                <a
                  href="https://www.instagram.com/jsalas92_/"
                  target="_blank"
                >
                  <Instagram />
                </a>
                <a
                  href="https://dribbble.com/jsalas"
                  target="_blank"
                >
                  <Dribbble />
                </a>
                <a
                  href="https://www.linkedin.com/in/jsalaslopez/"
                  target="_blank"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </kinesis-element>
      </div>
      <!-- Info bottom -->
      <div class="Home__wrapper-info">
        <p class="Home__wrapper-info-me">
          This site has been designed and programmed by me
        </p>
      <!-- <p class="Home__wrapper-info-scroll">Scroll to discover more</p> -->
      </div>
    </div>
  </kinesis-container>
</template>
<script>
import Github from './icons/Github.vue';
import Instagram from './icons/Instagram.vue';
import Dribbble from './icons/Dribbble.vue';
import Linkedin from './icons/Linkedin.vue';
import Sign from './icons/Sign.vue';
import TheLoader from './TheLoader.vue';
import { KinesisContainer, KinesisElement} from 'vue-kinesis';

export default {
  name: 'Home',
  components: {
    Github,
    Instagram,
    Dribbble,
    Linkedin,
    Sign,
    TheLoader,
    KinesisContainer,
    KinesisElement,
  },
  data() {
    return {
       publicPath: process.env.BASE_URL,
       isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = !this.isLoading;
    },1000)
  },
};
</script>
